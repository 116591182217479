import React, { useState } from "react";

const Personal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ position: "relative" }}>
      <button
        onClick={toggleDropdown}
        style={{
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
          color: "grey",
        }}
      >
        Personal
      </button>
      {isOpen && (
        <ul
          style={{
            position: "absolute",
            top: "100%",
            right: 0,
            backgroundColor: "#f8f8f8",
            padding: "10px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
          }}
        >
          <li>Manage Account</li>
          <li>View API Keys</li>
          <li>Terms</li>
          <li>Pricing</li>
          <li>Logout</li>
          <li>Login</li>
        </ul>
      )}
    </div>
  );
};

export default Personal;
