import React from "react";

const Documentation = () => {
  return (
    <div style={{ display: "flex" }}>
      {/* Left Sidebar */}
      <div
        style={{
          width: "20%",
          backgroundColor: "#f8f8f8",
          padding: "20px",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)"
        }}
      >
        <h3>Documentation</h3>
        <ul>
          <li>Section 1</li>
          <li>Section 2</li>
          <li>Section 3</li>
          {/* Add more sections as needed */}
        </ul>
      </div>

      {/* Main Content */}
      <div style={{ flex: 1, padding: "20px" }}>
        <h1>Documentation</h1>
        {/* Add documentation content and sections as needed */}
      </div>
    </div>
  );
};

export default Documentation;
