import React from "react";

const Logout = () => {
  return (
    <div>
      <h1>Logout</h1>
      {/* Add upgrade content and sections as needed */}
    </div>
  );
};

export default Logout;
