import React from "react";

const ApiReference = () => {
  return (
    <div style={{ display: "flex" }}>
      {/* Left Sidebar */}
      <div
        style={{
          width: "20%",
          backgroundColor: "#f8f8f8",
          padding: "20px",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)"
        }}
      >
        <h3>API Reference</h3>
        <ul>
          <li>Endpoint 1</li>
          <li>Endpoint 2</li>
          <li>Endpoint 3</li>
          {/* Add more endpoints as needed */}
        </ul>
      </div>

      {/* Main Content */}
      <div style={{ flex: 1, padding: "20px" }}>
        <h1>API Reference</h1>
        {/* Add API reference content and sections as needed */}
      </div>
    </div>
  );
};

export default ApiReference;
