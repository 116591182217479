import React from "react";

const Terms = () => {
  return (
    <div>
      <h1>Terms</h1>
      {/* Add upgrade content and sections as needed */}
    </div>
  );
};

export default Terms;
