import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Header.css'; // Import CSS file for header styles

const Header = () => {
  return (
    <header>
      <nav>
        <ul>
          <li>
            <NavLink exact to="/" activeClassName="active">
              Overview
            </NavLink>
          </li>
          <li>
            <NavLink to="/documentation" activeClassName="active">
              Documentation
            </NavLink>
          </li>
          <li>
            <NavLink to="/api-reference" activeClassName="active">
              API Reference
            </NavLink>
          </li>
          <li>
            <NavLink to="/examples" activeClassName="active">
              Examples
            </NavLink>
          </li>
          <li>
            <NavLink to="/playground" activeClassName="active">
              Playground
            </NavLink>
          </li>
          <li>
            <NavLink to="/upgrade" activeClassName="active">
              Upgrade
            </NavLink>
          </li>
          <li>
            <NavLink to="/help" activeClassName="active">
              Help
            </NavLink>
          </li>
          <li className="dropdown">
            <NavLink to="/personal" activeClassName="active">
              Personal
            </NavLink>
            <ul className="dropdown-content">
              <li>
                <Link to="/manage-account">Manage Account</Link>
              </li>
              <li>
                <Link to="/view-api-keys">View API Keys</Link>
              </li>
              <li>
                <Link to="/terms">Terms</Link>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/logout">Logout</Link>
              </li>
              
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
