import React from "react";

const ManageAccount = () => {
  return (
    <div>
      <h1>Manage Account</h1>
      {/* Add upgrade content and sections as needed */}
    </div>
  );
};

export default ManageAccount;
