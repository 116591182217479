import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Overview from './containers/overview/overview';
import Documentation from './containers/documentation/documentation';
import ApiReference from './containers/apireference/apireference';
import Examples from './containers/examples/examples';
import Playground from './containers/playground/playground';
import Upgrade from './containers/upgrade/upgrade';
import Help from './containers/help/help';
import Personal from './containers/personal/personal';
import ManageAccount from './containers/manageaccount/manageaccount';
import ViewApiKeys from './containers/viewapikeys/viewapikeys';
import Terms from './containers/terms/terms';
import Pricing from './containers/pricing/pricing';
import Login from './components/login/login';
import Logout from './containers/logout/logout';
import Signup from './components/signup/signup';
function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Overview />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/api-reference" element={<ApiReference />} />
          <Route path="/examples" element={<Examples />} />
          <Route path="/playground" element={<Playground />} />
          <Route path="/upgrade" element={<Upgrade />} />
          <Route path="/help" element={<Help />} />
          <Route path="/personal" element={<Personal />} />
          <Route path="/manage-account" element={<ManageAccount />} />
          <Route path="/view-api-keys" element={<ViewApiKeys />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
