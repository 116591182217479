import React from "react";

const Login = () => {
  return (
    <div>
      <h1>Login</h1>
      {/* Add upgrade content and sections as needed */}
    </div>
  );
};

export default Login;
